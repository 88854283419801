import { isAuthenticated } from "../authentication/authentication";
import { LOCAL_STORAGE } from "../storage/constants";
import { localStorageHelper } from "../storage/local_storage";

export function setLoginLanguage(id, value) {
  sessionStorage.setItem(LOCAL_STORAGE.USER_LANGUAGE_LOGIN, JSON.stringify({ id, value }));
}

export function getLoginLanguage() {
  return JSON.parse(sessionStorage.getItem(LOCAL_STORAGE.USER_LANGUAGE_LOGIN));
}

export function getLabel(label) {
  if (!label) {
    return "";
  }

  const key = label.toUpperCase();
  let language = getUserLanguage();
  let translates = LoginLabels;

  if (language === undefined || translates === undefined) {
    return `key_${key}_lang_${language}_NOTFOUND`;
  }
  let translateFounded = translates.find((x) => x.Mnemonica === key && x[language]);
  if (translateFounded === undefined) {
    return `key_${key}_lang_${language}_NOTFOUND`;
  } else {
    return translateFounded[language];
  }
}

export function getLabelWithData(messageId, data) {
  let message = getLabel(messageId);

  data.forEach((d, index) => {
    message = message.replace("{" + index + "}", d);
  });

  return message;
}

export function getLink(key) {
  if (!key) {
    return "";
  }

  key = key.toUpperCase();
  let urls = loginLinks;

  if (urls === undefined) {
    return `key_${key}_URL_NOTFOUND`;
  }
  let urlFounded = urls.find((x) => x.Mnemonica === key);
  if (urlFounded === undefined) {
    return `key_${key}_URL_NOTFOUND`;
  } else {
    return urlFounded.URL;
  }
}

export function getUserLanguage() {
  let language = isAuthenticated() ? localStorageHelper.getUserLanguage() : !navigator.cookieEnabled ? null : getLoginLanguage()?.value;

  if (language === undefined || language === null) {
    return "PT";
  } else {
    if (language.split("-").length === 2) language = language.split("-")[1];

    return language;
  }
}

const loginLinks = [
  {
    Mnemonica: "KNOW_MORE_SECURITY",
    URL: "https://www.bancocarregosa.com/pt/seguranca/privacidade-e-cookies/",
  },
  {
    Mnemonica: "CHAVE_MOVEL_DIGITAL",
    URL: "https://www.autenticacao.gov.pt/a-chave-movel-digital/",
  },
];

const LoginLabels = [
  {
    Mnemonica: "SUCCESS_LOGOUT_FEEDBACK",
    PT: "Obrigado por investir o seu património com o Banco Carregosa. A sua sessão encontra-se agora encerrada.",
    US: "Thank you for investing your assets with Banco Carregosa. Your session is now closed.",
    ES: "Obrigado por investir o seu património com o Banco Carregosa. A sua sessão encontra-se agora encerrada.",
  },
  {
    Mnemonica: "INTERNAL_ERROR_TITLE",
    PT: "Erro interno",
    US: "Internal error",
    ES: "Erro interno",
  },
  {
    Mnemonica: "INTERNAL_ERROR_DESC_1",
    PT: "Algo inesperado ocorreu durante a sua navegação.",
    US: "Something wrong happened during the navigation.",
    ES: "Algo inesperado ocorreu durante a sua navegação.",
  },
  {
    Mnemonica: "INTERNAL_ERROR_DESC_2",
    PT: "A nossa equipa já se encontra a resolver esta situação. Por favor, tente mais tarde.",
    US: "Our team is working to fix the problem. Please, try again later.",
    ES: "A nossa equipa já se encontra a resolver esta situação. Por favor, tente mais tarde.",
  },
  {
    Mnemonica: "ACCESS_ACCOUNT",
    PT: "Entrar na minha conta",
    US: "Account Login",
    ES: "Entrar na minha conta",
  },
  {
    Mnemonica: "ACCESS_RECOVERY",
    PT: "Recuperar Acesso",
    US: "Recover Access",
    ES: "Recuperar Acesso",
  },
  {
    Mnemonica: "USERNAME",
    PT: "ID Utilizador",
    US: "User ID",
    ES: "ID Utilizador",
  },
  {
    Mnemonica: "LOGINASUSER",
    PT: "Login",
    US: "Login",
    ES: "Login",
  },
  {
    Mnemonica: "PASSWORD",
    PT: "Código de Acesso",
    US: "Password",
    ES: "Código de Acesso",
  },
  {
    Mnemonica: "ENTER",
    PT: "Entrar",
    US: "Login",
    ES: "Entrar",
  },
  {
    Mnemonica: "LOGIN_REQUIRED_ERROR",
    PT: "Utilizador é obrigatório!",
    US: "User ID is required!",
    ES: "Utilizador é obrigatório!",
  },
  {
    Mnemonica: "PASSWORD_REQUIRED_ERROR",
    PT: "Código de acesso é obrigatório!",
    US: "Password is required!",
    ES: "Código de acesso é obrigatório!",
  },
  {
    Mnemonica: "GENERIC_ERROR",
    PT: "Ocorreu um erro, tente novamente mais tarde!",
    US: "An error as occurred, please try again later!",
    ES: "Ocorreu um erro, tente novamente mais tarde!",
  },
  {
    Mnemonica: "GENERIC_SUCCESS",
    PT: "Operação realizada com sucesso!",
    US: "Operation completed successfully!",
    ES: "Operação realizada com sucesso!",
  },
  {
    Mnemonica: "GOVERNMENT_AUTH",
    PT: "AUTENTICAÇÃO.GOV",
    US: "AUTENTICAÇÃO.GOV",
    ES: "AUTENTICAÇÃO.GOV",
  },
  {
    Mnemonica: "RECOVERY",
    PT: "Recuperar",
    US: "Recover",
    ES: "Recuperar",
  },
  {
    Mnemonica: "ACCESS_CODE",
    PT: "credenciais de acesso",
    US: "Login password",
    ES: "credenciais de acesso",
  },
  {
    Mnemonica: "KNOW_MORE",
    PT: "Saiba mais",
    US: "Learn more",
    ES: "Saiba mais",
  },
  {
    Mnemonica: "ABOUT_CHAVE",
    PT: "sobre a Chave Móvel Digital",
    US: "about Chave Móvel Digital",
    ES: "sobre a Chave Móvel Digital",
  },
  {
    Mnemonica: "SECURITY_WARNING",
    PT: "AVISOS DE SEGURANÇA",
    US: "SECURITY WARNING",
    ES: "AVISOS DE SEGURANÇA",
  },
  {
    Mnemonica: "SECURITY_WARNING_1",
    PT: "O Banco Carregosa nunca envia qualquer tipo de comunicação aos seus clientes em que solicite o código de acesso ao site ou plataformas.",
    US: "Banco Carregosa never sends any type of communication to its clients requesting any password or access credentials to its website or platforms.",
    ES: "O Banco Carregosa nunca envia qualquer tipo de comunicação aos seus clientes em que solicite o código de acesso ao site ou plataformas.",
  },
  {
    Mnemonica: "SECURITY_WARNING_2",
    PT: "Assim como, não envia links de acesso direto à sua conta para o endereço de email. Por solicitação direta do cliente e após confirmação, apenas se remete endereços ou links para download das aplicações GoBulling Pro.",
    US: "As it never sends to your email any link to access your account directly. At the client’s request, and after validation, only links to download our GoBulling Pro platforms may be sent.",
    ES: "Assim como, não envia links de acesso direto à sua conta para o endereço de email. Por solicitação direta do cliente e após confirmação, apenas se remete endereços ou links para download das aplicações GoBulling Pro.",
  },
  {
    Mnemonica: "CLICK",
    PT: "Clique",
    US: "Click",
    ES: "Clique",
  },
  {
    Mnemonica: "HERE",
    PT: "aqui",
    US: "here",
    ES: "aqui",
  },
  {
    Mnemonica: "MORE_INFO",
    PT: "para mais informações.",
    US: "for more information.",
    ES: "para mais informações.",
  },
  {
    Mnemonica: "UNAUTHORIZED",
    PT: "A sua sessão expirou!",
    US: "Your session has expired!",
    ES: "A sua sessão expirou!",
  },
  {
    Mnemonica: "COOKIES_DISABLED",
    PT: "Este site utiliza cookies. Para continuar, deverá aceitar os cookies nas preferências do seu navegador.",
    US: "This site uses cookies. To advance, you have to allow cookies in your browser preferences.",
    ES: "Este site utiliza cookies. Para continuar, deverá aceitar os cookies nas preferências do seu navegador.",
  },
  {
    Mnemonica: "ERROR",
    PT: "Lamentamos, ocorreu um erro interno. Tente novamente mais tarde.",
    US: "We're sorry, an internal error has occurred. Please try again later.",
    ES: "Lamentamos, ocorreu um erro interno. Tente novamente mais tarde.",
  },
  {
    Mnemonica: "COOKIE_INFO",
    PT: "Para melhorar a sua experiencia de navegação este website utiliza cookies. Ao prosseguir a navegação está a consentir a sua utilização. Para saber mais por favor leia a",
    US: "We use cookies to improve your experience on this website. By proceeding, you consent to cookies. To learn more, please read the",
    ES: "Para melhorar a sua experiencia de navegação este website utiliza cookies. Ao prosseguir a navegação está a consentir a sua utilização. Para saber mais por favor leia a",
  },
  {
    Mnemonica: "PRIVACY_POLICY",
    PT: "Política de Privacidade",
    US: "Privacy Policy",
    ES: "Política de Privacidade",
  },
  {
    Mnemonica: "CLOSE",
    PT: "Fechar",
    US: "Close",
    ES: "Fechar",
  },
  {
    Mnemonica: "LOADING",
    PT: "A Carregar",
    US: "Loading",
    ES: "Carregando",
  },
  {
    Mnemonica: "USER",
    PT: "Utilizador",
    US: "User",
    ES: "Utilizador",
  },
  {
    Mnemonica: "NIF",
    PT: "Número de Contribuinte",
    US: "VAT Number",
    ES: "Número de Contribuinte",
  },
  {
    Mnemonica: "EMAIL",
    PT: "Email",
    US: "Email",
    ES: "Email",
  },
  {
    Mnemonica: "SECURITY_CODE",
    PT: "Código de Segurança",
    US: "Security Code",
    ES: "Código de Segurança",
  },
  {
    Mnemonica: "SEND",
    PT: "Enviar Pedido",
    US: "Send Request",
    ES: "Enviar Pedido",
  },
  {
    Mnemonica: "NEW_CODE",
    PT: "Novo Código",
    US: "New password",
    ES: "Novo Código",
  },
  {
    Mnemonica: "SEND_SUCCEED",
    PT: "Pedido registado!",
    US: "Request Sent!",
    ES: "Pedido registado!",
  },
  {
    Mnemonica: "SEND_SUCCEED_TEXT",
    PT: "O seu pedido foi registado com sucesso, pelo que irá ser contactado brevemente pelos nossos serviços.",
    US: "Your request has been sent successfully, you'll be contacted by our services shortly.",
    ES: "O seu pedido foi registado com sucesso, pelo que irá ser contactado brevemente pelos nossos serviços.",
  },
  {
    Mnemonica: "THANKS",
    PT: "Obrigado!",
    US: "Thank You!",
    ES: "Obrigado!",
  },
  {
    Mnemonica: "SEND_FAILED",
    PT: "Ocorreu um problema!",
    US: "An issue as occurred!",
    ES: "Ocorreu um problema!",
  },
  {
    Mnemonica: "SEND_FAILED_TEXT",
    PT: "Pedimos desculpas, mas no momento não podemos proceder com seu pedido, pedimos a gentileza de tentar novamente nos próximos minutos.",
    US: "We're sorry, but we could not process your request right now. Please try again in a few minutes.",
    ES: "Pedimos desculpas, mas no momento não podemos proceder com seu pedido, pedimos a gentileza de tentar novamente nos próximos minutos.",
  },
  {
    Mnemonica: "CONFIRM",
    PT: "confirmar",
    US: "confirm",
    ES: "confirmar",
  },
  {
    Mnemonica: "BACK",
    PT: "Voltar",
    US: "Back",
    ES: "Voltar",
  },
  {
    Mnemonica: "CHANGE_PASSWORD_ERROR",
    PT: "Ocorreu um erro ao atualizar o seu código de acesso. Por favor, tente novamente.",
    US: "An error has occurred when changing the password. Please try again.",
    ES: "Ocorreu um erro ao atualizar o seu código de acesso. Por favor, tente novamente.",
  },
  {
    Mnemonica: "CHANGE_PASSWORD_SUCCESS",
    PT: "Código de acesso alterado com sucesso.",
    US: "Password has been successfully changed.",
    ES: "Código de acesso alterado com sucesso.",
  },
  {
    Mnemonica: "NEW_PASSWORD_WARNING",
    PT: "O seu novo código de acesso não respeita os requisitos.",
    US: "Your new password doesn't meet the requirements.",
    ES: "O seu novo código de acesso não respeita os requisitos.",
  },
  {
    Mnemonica: "CHANGE_PASSWORD_PAGE_TITLE",
    PT: "O seu código de acesso expirou e não pode voltar a ser utilizado. Por favor defina um novo código de acesso.",
    US: "Your password has expired and must be changed. Please create a new passowrd.",
    ES: "O seu código de acesso expirou e não pode voltar a ser utilizado. Por favor defina um novo código de acesso.",
  },
  {
    Mnemonica: "INVALID_PASSWORD",
    PT: "Código de acesso inválido.",
    US: "Invalid password.",
    ES: "Código de acesso inválido.",
  },
  {
    Mnemonica: "NEW_PASSWORD",
    PT: "Novo código de acesso",
    US: "New password",
    ES: "Novo código de acesso",
  },
  {
    Mnemonica: "CONFIRM_PASSWORD",
    PT: "Confirmar código de acesso",
    US: "Confirm password",
    ES: "Confirmar código de acesso",
  },
  {
    Mnemonica: "PASSWORD_VERY_WEAK",
    PT: "Muito Fraca",
    US: "Very weak",
    ES: "Muito Fraca",
  },
  {
    Mnemonica: "PASSWORD_WEAK",
    PT: "Fraca",
    US: "Weak",
    ES: "Fraca",
  },
  {
    Mnemonica: "PASSWORD_MEDIUM",
    PT: "Média",
    US: "Average",
    ES: "Média",
  },
  {
    Mnemonica: "PASSWORD_GOOD",
    PT: "Boa",
    US: "Good",
    ES: "Boa",
  },
  {
    Mnemonica: "PASSWORD_EXCELENT",
    PT: "Excelente",
    US: "Excellent",
    ES: "Excelente",
  },
  {
    Mnemonica: "CHANGE_PASSWORD_INVALID",
    PT: "Os códigos de acesso não coincidem.",
    US: "Passwords do not match.",
    ES: "Os códigos de acesso não coincidem.",
  },
  {
    Mnemonica: "NEW_PASSWORD_LABEL",
    PT: "O seu novo código de acesso deverá atender ao seguinte:",
    US: "Your new password should consider the following:",
    ES: "O seu novo código de acesso deverá atender ao seguinte:",
  },
  {
    Mnemonica: "SECURITY_WARNING_TITLE",
    PT: "AVISOS DE SEGURANÇA",
    US: "SECURITY WARNING",
    ES: "AVISOS DE SEGURANÇA",
  },
  {
    Mnemonica: "SECURITY_WARNING_TEXT",
    PT: "<p>O Banco Carregosa nunca envia qualquer tipo de comunicação aos seus clientes em que solicite o código de acesso ao site ou plataformas.</p><p>Assim como, não envia links de acesso direto à sua conta para o endereço de email. Por solicitação direta do cliente e após confirmação, apenas se remete endereços ou links para download das aplicações GoBulling Pro.</p>",
    US: "<p>Banco Carregosa never sends any type of communication to its clients requesting any password or access credentials to its website or platforms.</p><p>As it never sends to your email any link to access your account directly. At the client’s request, and after validation, only links to download our GoBulling Pro platforms may be sent.</p>",
    ES: "<p>O Banco Carregosa nunca envia qualquer tipo de comunicação aos seus clientes em que solicite o código de acesso ao site ou plataformas.</p><p>Assim como, não envia links de acesso direto à sua conta para o endereço de email. Por solicitação direta do cliente e após confirmação, apenas se remete endereços ou links para download das aplicações GoBulling Pro.</p>",
  },
  {
    Mnemonica: "SECURITY_WARNING_LINK",
    PT: "Clique <a target={1} class={2} href={0} rel='noreferrer'>aqui</a> para mais informações.",
    US: "Click <a target={1} class={2} href={0} rel='noreferrer'>here</a> for more information.",
    ES: "Clique <a target={1} class={2} href={0} rel='noreferrer'>aqui</a> para mais informações.",
  },
  {
    Mnemonica: "RECOVERY_OPERATION_ERROR_TITLE",
    PT: "Ocorreu um erro",
    US: "An error has occurred",
    ES: "Ocorreu um erro",
  },
  {
    Mnemonica: "RECOVERY_OPERATION_ERROR_SUFIX",
    PT: "Por favor tente mais tarde ou contacte a linha de Apoio ao Cliente 808 10 20 20.\nObrigado!",
    US: "Please try again later ou contact our Customer Support at 808 10 20 20.\nThank you!",
    ES: "Por favor tente mais tarde ou contacte a linha de Apoio ao Cliente 808 10 20 20.\nObrigado!",
  },
  {
    Mnemonica: "RECOVERY_REMOVE_REQUEST_TITLE",
    PT: "Remover pedido",
    US: "Remove request",
    ES: "Remover pedido",
  },
  {
    Mnemonica: "RECOVERY_REMOVE_REQUEST_TEXT",
    PT: "Vai cancelar o pedido de recuperação de código de acesso. Todos os links presentes no email de recuperação serão invalidados. Deseja continuar?",
    US: "You're about to cancel the password recovery rquest. All the links included in the recovery email will be invalidated. Do you wish to proceed?",
    ES: "Vai cancelar o pedido de recuperação de código de acesso. Todos os links presentes no email de recuperação serão invalidados. Deseja continuar?",
  },
  {
    Mnemonica: "RECOVERY_REMOVE_REQUEST_ERROR",
    PT: "Ocorreu um erro ao tentar cancelar o pedido de recuperação de código de acesso.",
    US: "An error has ocurred when trying to cancel the password recovery request.",
    ES: "Ocorreu um erro ao tentar cancelar o pedido de recuperação de código de acesso.",
  },
  {
    Mnemonica: "RECOVERY_REMOVE_REQUEST_SUCCESS",
    PT: "O seu pedido de recuperação de ID de utilizador foi removido com sucesso. Todos os links presentes no seu email de recuperação de ID de utilizador foram invalidados.",
    US: "Your request to recover the User ID has been successfully cancelled. All the links included in the User ID recovery email have been invalidated.",
    ES: "O seu pedido de recuperação de ID de utilizador foi removido com sucesso. Todos os links presentes no seu email de recuperação de ID de utilizador foram invalidados.",
  },
  {
    Mnemonica: "RECOVERY_BLOCK_USER_TITLE",
    PT: "Bloquear utilizador",
    US: "Block user",
    ES: "Bloquear utilizador",
  },
  {
    Mnemonica: "RECOVERY_BLOCK_USER_TEXT",
    PT: "O acesso às contas do seu utilizador ficará bloqueado. Deseja continuar?",
    US: "Access to your user accounts will be blocked. Do you wish to proceed?",
    ES: "O acesso às contas do seu utilizador ficará bloqueado. Deseja continuar?",
  },
  {
    Mnemonica: "RECOVERY_BLOCK_USER_ERROR",
    PT: "Ocorreu um erro ao tentar bloquear o seu utilizador.",
    US: "An error has occurred when trying to block your user.",
    ES: "Ocorreu um erro ao tentar bloquear o seu utilizador.",
  },
  {
    Mnemonica: "RECOVERY_BLOCK_USER_SUCCESS",
    PT: "O seu utilizador encontra-se bloqueado, pelo que o acesso às suas contas não está autorizado. Para que possa desbloquear o utilizador será necessário entrar em contacto com a linha de Apoio ao Cliente 808 10 20 20.\nObrigado!",
    US: "Your user is blocked, and access to the accounts is not authorized. To unblock the user please contact our Customer Support at 808 10 20 20.\nThank you!",
    ES: "O seu utilizador encontra-se bloqueado, pelo que o acesso às suas contas não está autorizado. Para que possa desbloquear o utilizador será necessário entrar em contacto com a linha de Apoio ao Cliente 808 10 20 20.\nObrigado!",
  },
  {
    Mnemonica: "RECOVERY_REVOKE_REQUEST_TITLE",
    PT: "Revogar pedidos",
    US: "Revoke request",
    ES: "Revogar pedidos",
  },
  {
    Mnemonica: "RECOVERY_REVOKE_REQUEST_TEXT",
    PT: "Vai revogar todos os pedidos de recuperação de código de acesso, impedindo também novos pedidos nas próximas 24 horas. Deseja continuar?",
    US: "You will revoke all recovery requests and disable all new requests in the next 24 hours. Do you wish to proceed?",
    ES: "Vai revogar todos os pedidos de recuperação de código de acesso, impedindo também novos pedidos nas próximas 24 horas. Deseja continuar?",
  },
  {
    Mnemonica: "RECOVERY_REVOKE_REQUEST_ERROR",
    PT: "Ocorreu um erro ao tentar cancelar o pedido de recuperação de código de acesso.",
    US: "An error has occurred when trying to cancel the password recovery request.",
    ES: "Ocorreu um erro ao tentar cancelar o pedido de recuperação de código de acesso.",
  },
  {
    Mnemonica: "RECOVERY_REVOKE_REQUEST_SUCCESS",
    PT: "O seu pedido de recuperação de código de acesso foi removido com sucesso. Todos os links presentes  no seu email de recuperação de código de acesso foram invalidados.",
    US: "Your request to recover the password has been successfully cancelled. All the links included in the password recovery email have been invalidated.",
    ES: "O seu pedido de recuperação de código de acesso foi removido com sucesso. Todos os links presentes  no seu email de recuperação de código de acesso foram invalidados.",
  },
  {
    Mnemonica: "RECOVERY_CHANGE_PASSWORD_TITLE",
    PT: "Criar código de acesso",
    US: "Create password",
    ES: "Criar código de acesso",
  },
  {
    Mnemonica: "RECOVERY_CHANGE_PASSWORD_TEXT",
    PT: "Para avançar, deverá criar o seu código de acesso:",
    US: "To advance, you have to create your password:",
    ES: "Para avançar, deverá criar o seu código de acesso:",
  },
  {
    Mnemonica: "RECOVERY_CHANGE_PASSWORD_SUCCESS",
    PT: "Código de acesso criado com sucesso.",
    US: "Password successfully created.",
    ES: "Código de acesso criado com sucesso.",
  },
  {
    Mnemonica: "RECOVERY_EXPIRED_LINK_TITLE",
    PT: "Pedido expirado",
    US: "Request expired",
    ES: "Pedido expirado",
  },
  {
    Mnemonica: "RECOVERY_EXPIRED_LINK",
    PT: "Este pedido de recuperação de código de acesso encontra-se expirado, pelo que terá de efectuar um novo pedido.\nObrigado!",
    US: "This password recovery request has expired. Please create a new request.\nThank you!",
    ES: "Este pedido de recuperação de código de acesso encontra-se expirado, pelo que terá de efectuar um novo pedido.\nObrigado!",
  },  
  {
    Mnemonica: "TAX_NUMBER",
    PT: "Número de Identificação Fiscal",
    US: "Tax Identification Number (NIF)",
    ES: "Número de Identificação Fiscal",
  },
  {
    Mnemonica: "NIF_REQUIRED_ERROR",
    PT: "Número de identificação fiscal é obrigatório!",
    US: "Tax identification number mandatory!",
    ES: "Número de identificação fiscal é obrigatório!",
  },
  {
    Mnemonica: "DMIF_ACCESS",
    PT: "Aceder ao questionário",
    US: "Access the questionnaire",
    ES: "Aceder ao questionário",
  },
  {
    Mnemonica: "DIALOG_AUTH_TITLE",
    PT: "Faça a confirmação para continuar",
    US: "Confirm to proceed",
    ES: "Faça a confirmação para continuar",
  },
  {
    Mnemonica: "LOGOUT",
    PT: "Sair",
    US: "Logout",
    ES: "Salir",
  },
  {
    Mnemonica: "CANCEL_CONFIRMATION_1",
    PT: "Ao sair, você perderá todos os dados não guardados, confirma?",
    US: "When you log out, you will lose all unsaved data, confirm?",
    ES: "Ao sair, você perderá todos os dados não salvos, confirma?",
  },
  {
    Mnemonica: "CANCEL_CONFIRMATION_2",
    PT: "Ao sair, a operação não será confirmada e poderá perder dados não guardados, deseja continuar?",
    US: "If you log out, the operation will not be confirmed and you may lose unsaved data. Do you want to continue?",
    ES: "Ao sair, a operação não será confirmada e poderá perder dados não salvos, deseja continuar?",
  },
  {
    Mnemonica: "UNEXPECTED_ERROR",
    PT: "Erro inesperado, por favor tente novamente em breve!",
    US: "Unexpected error, please try again later!",
    ES: "Erro inesperado, por favor tente novamente em breve!",
  },
  {
    Mnemonica: "INVALID_CREDENTIALS",
    PT: "Utilizador ou código de acesso inválidos!",
    US: "Invalid user or password!",
    ES: "Utilizador ou código de acesso inválidos!",
  },
  {
    Mnemonica: "INVALID_NIF",
    PT: "Não foi possível realizar a operação, por favor contacte o seu gestor de conta ou private banker.",
    US: "It was not possible to proceed, please contact your account manager or private banker.",
    ES: "Não foi possível realizar a operação, por favor contacte o seu gestor de conta ou private banker.",
  },
  {
    Mnemonica: "INCORRECT_PIN",
    PT: "O código inserido é inválido!",
    US: "The entered code is invalid!",
    ES: "O código inserido é inválido!",
  }
];